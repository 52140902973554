// Generated by Framer (16060ad)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["XwF2I6K59"];

const variantClassNames = {XwF2I6K59: "framer-v-5vmp5d"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "XwF2I6K59", image: nHqOYll8m = {src: new URL("assets/512/w5c0ECpWEHtKh117L2oXIrov9RU.png", import.meta.url).href, srcSet: `${new URL("assets/512/w5c0ECpWEHtKh117L2oXIrov9RU.png", import.meta.url).href} 512w, ${new URL("assets/1024/w5c0ECpWEHtKh117L2oXIrov9RU.png", import.meta.url).href} 1024w, ${new URL("assets/w5c0ECpWEHtKh117L2oXIrov9RU.png", import.meta.url).href} 1520w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XwF2I6K59", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5nFLQ", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "stretch", ...toResponsiveImage(nHqOYll8m)}} className={cx("framer-5vmp5d", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XwF2I6K59"} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5nFLQ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5nFLQ .framer-19v9lhs { display: block; }", ".framer-5nFLQ .framer-5vmp5d { height: 339px; mix-blend-mode: hard-light; position: relative; width: 380px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 339
 * @framerIntrinsicWidth 380
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"nHqOYll8m":"image"}
 */
const FramerfYyptiAdR: React.ComponentType<Props> = withCSS(Component, css, "framer-5nFLQ") as typeof Component;
export default FramerfYyptiAdR;

FramerfYyptiAdR.displayName = "Card/Card Tutorials";

FramerfYyptiAdR.defaultProps = {height: 339, width: 380};

addPropertyControls(FramerfYyptiAdR, {nHqOYll8m: {__defaultAssetReference: "data:framer/asset-reference,w5c0ECpWEHtKh117L2oXIrov9RU.png?originalFilename=8+1.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerfYyptiAdR, [])